.ar-common-search-panel {
  .react-datepicker__close-icon {
    right: 6px;
    &::after {
      background: transparent;
      color: var(--th-tertiary-color-alt);
      font-size: 26px;
      font-weight: $font-weight-medium;
    }
  }
}

.react-datepicker {
  &__day {
    &:hover {
      background: $drop-down-hover;
      color: color('pure-white');
    }
    &--selected {
      background: color('primary');
      &:hover {
        background: rgba(color('primary'), 0.9);
      }
    }
  }
}
