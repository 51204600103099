.ar-sortable__drag-area {
  position: relative;
  .ar-edit-page__details {
    display: none;
  }
  img {
    opacity: 0;
  }
  &::after {
    @include before-after;
    outline: 1px dashed var(--th-white-gray);
    outline-offset: 15px;
    inset: 50px;
  }
  &::before {
    @include before-after;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--th-white-invet);
    font-size: large;
    content: 'DROP HERE';
    z-index: 2;
    inset: 0;
  }
}
