// sass-lint:disable no-misspelled-properties
.ar-common-post {
  $post: '.ar-common-post';
  display: flex;
  position: relative;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid $th-post-border;
  border-radius: 10px;
  background-color: $th-secondary-color;
  &:hover {
    box-shadow: $th-shadow-hover;
  }
  .image-container {
    position: relative;
    max-height: 175px;
    border-radius: 10px 10px 0 0;
    background-color: $post-bg-color;
    cursor: pointer;
    aspect-ratio: 2 / 1;
    .badge {
      position: absolute;
      top: -8px;
      left: -2px;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: color('primary-light');
      color: color('pure-white');
      font-family: $lota-grotesque-regular;
      font-size: $h5-font-size;
      font-weight: $font-weight-normal;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px 20px 0 0;
      object-fit: contain;
      aspect-ratio: 16 / 9;
    }
    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75px;
      height: 75px;
      transform: translateY(-50%) translateX(-50%);
      border-radius: 50%;
      background-image: url('../../../images/play-button.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      content: '';
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  &__checkbox-wrapper {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: 1;
    .form-control,
    .ar-common-checkbox {
      cursor: pointer;
      input {
        &[type='checkbox'] {
          right: 0;
          &:checked + span {
            background: color('primary');
            &::after {
              transform: rotate(45deg);
              border: solid #fff;
              border-width: 0 3px 3px 0;
              background: none;
            }
          }
        }
      }
    }
  }
  &__container {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    padding: 10px 60px 15px 17px;
    color: $th-primary-color-alt;
  }
  &__cat,
  &__type {
    color: $component-text-color;
    font-family: $lota-grotesque-regular;
    font-size: 12px;
    line-height: 1.4;
    span {
      font-family: $lota-grotesque-regular;
    }
  }
  &__title {
    @include body-normal;
    margin: 0 0 3px;
    color: var(--th-breadcrumb-color);
    font-family: $lota-grotesque-regular;
    letter-spacing: 0.5px;
    line-height: normal;
  }
  &__cat {
    p,
    a {
      color: $th-secondary-color-alt;
      font-size: $h4-font-size;
      line-height: 1.5;
    }
    a {
      margin-bottom: 3px;
      text-decoration: underline;
      &:hover {
        color: color('primary');
      }
    }
    .ar-common-post__tag {
      color: var(--th-tag-text);
    }
  }
  &__link {
    @include line-clamp(1);
  }
  &__action {
    display: flex;
    position: absolute;
    top: 25px;
    right: 15px;
    flex-wrap: wrap;
    margin: auto 0 0;
    transform: translateY(-50%);
    gap: 0 10px;
  }
  &--download {
    #{$post}__container {
      padding: 10px 27px 18px 17px;
    }
    #{$post}__action {
      position: static;
      justify-content: flex-end;
      margin-top: 10px;
      transform: inherit;
      gap: 5px 10px;
    }
    .btn-group {
      .btn-outline-info {
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        &.dropdown-toggle,
        &.dropdown-toggle:focus {
          height: 30px;
          border: 2px solid $component-text-color;
          border-radius: 15px;
          background-color: transparent;
          box-shadow: none;
        }
        &.dropdown-toggle {
          @include transition;
          padding: 4px 12px;
          color: $component-text-color;
          text-transform: none;
          &:hover {
            border-color: $download-color;
            color: $download-color;
          }
        }
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        &.dropdown-toggle:focus {
          border-color: $download-color;
          color: $download-color;
          &::after {
            background-image: url('../#{$download-hover-drop}');
          }
        }
      }
      &.show {
        > button {
          background-color: color('primary');
        }
      }
      > button {
        padding: 8px 15px;
        border-color: color('primary');
        color: color('primary');
        font-size: 12px;
        text-transform: uppercase;
        &::after {
          position: relative;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 6px;
          border-width: 0;
          background-image: url('../#{$download-drop}');
          background-repeat: no-repeat;
          background-size: contain;
        }
        &:hover {
          &::after {
            background-image: url('../#{$download-hover-drop}');
          }
        }
      }
      .dropdown-menu {
        z-index: 100;
        button {
          padding: 3px 15px;
          font-size: 12px;
          text-transform: uppercase;
        }
        .dropdown-item {
          &:hover,
          &:focus {
            color: color('pure-white');
          }
          &:hover {
            background: $drop-down-hover;
          }
          &:focus,
          &:active {
            background: color('primary');
          }
        }
      }
    }
  }
  &__tag {
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 5px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 3px;
    background: rgba(color('primary'), 0.5);
  }
}

@media (min-width: 3500px) {
  .ar-common-post--download {
    .btn-group {
      > button {
        font-size: 14px;
      }
    }
  }
}
