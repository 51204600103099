.ar-common-float-button {
  display: inline-block;
  margin: 0 0 20px;
  z-index: 1021;
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: left;
    border-radius: 10px;
    // sass-lint:disable no-misspelled-properties
    gap: 0 10px;
    .ar-btn,
    div {
      margin: 15px 0;
      transition: opacity 0.3s ease;
      border: 0;
      border-radius: 100px;
      background-repeat: no-repeat;
      background-position: center left 16px;
      background-size: 24px;
      cursor: pointer;
      &--primary {
        padding: 7px 22px 6px;
      }
      &:hover {
        background-color: color('primary');
      }
      &.btn-figma {
        padding-left: 44px;
        background-image: url('../../../images/figma_icon.svg');
      }
      &.btn-update {
        padding-left: 38px;
        background-image: url('../../../images/save.svg');
        background-size: 15px;
      }
      &.btn-back,
      &.btn-cancel {
        position: relative;
        padding-left: 30px;
        &::before {
          @include before-after;
          top: 50%;
          left: 16px;
          width: 8px;
          height: 8px;
          transform: translateY(-50%) rotate(45deg);
          border-bottom: 3px solid color('primary');
          border-left: 3px solid color('primary');
        }
        &:hover {
          &::before {
            border-color: #fff;
          }
        }
      }
      &.btn-create {
        padding-left: 40px;
        &::before {
          @include before-after;
          @include plus-icon;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    button:disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }
}

.ar-common-float-button--offset {
  .ar-common-float-button__inner {
    position: relative;
  }
}

@media (min-width: 3500px) {
  .ar-common-float-button {
    &__inner {
      .ar-btn,
      div {
        cursor: pointer;
        &--primary {
          padding: 7px 38px 6px;
        }
        &.btn-update {
          padding-left: 50px;
          background-position: center left 23px;
        }
        &.btn-back,
        &.btn-cancel {
          padding-left: 50px;
          &::before {
            left: 24px;
          }
        }
        &.btn-create {
          padding-left: 50px;
          &::before {
            @include before-after;
            @include plus-icon;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal-open {
    .ar-common-float-button {
      // sass-lint:disable no-important
      right: 15px !important;
    }
  }
  .ar-common-float-button {
    position: fixed;
    top: 0;
    right: 30px;
    &__inner {
      width: fit-content;
      margin: 0 0 0 auto;
    }
  }
}
