//font sizes
$base-size: 16;
$font-family-base: 'lota-grotesque-regular', sans-serif;

$h1-font-size: 36px;
$h2-font-size: 20px;
$h3-font-size: 16px;
$h4-font-size: 15px;
$h5-font-size: 14px;
$h6-font-size: 13px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;
