// sass-lint:disable indentation, function-name-format, quotes
.login-body-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 4px;
  z-index: 10;
  .main-brand {
    width: 169px;
    height: 47px;
    margin: 80px auto 0;
    object-fit: cover;
  }
  .title {
    justify-content: center;
    min-height: calc(100vh - 210px);
    margin-bottom: 1px;
    padding-top: 15px;
    .main-title {
      padding: 0 25px;
      border-right: 1px solid rgba(255, 255, 255, 0.6);
      color: var(--th-text-white);
      font-size: 40px;
      font-weight: $font-weight-light;
    }
  }
  .google-login {
    justify-content: center;
    padding: 0 25px;
    &__button {
      display: flex;
      align-items: center;
      padding: 20px 20px 20px 80px;
      transition: background-color 0.15s, border-color 0.15s ease-in;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 100px;
      background: url('../#{$icon-gogle}') no-repeat left 22px center / 43px;
      color: #fff;
      font-size: 20px;
      &::after {
        display: inline-block;
        width: 45px;
        height: 18px;
        margin-left: 15px;
        transition: transform 0.15s ease-in;
        background: url('../#{$icon-long-arrow-right}') no-repeat left center / contain;
        content: "";
      }
      &:hover {
        border-color: var(--th-primary-light);
        background-color: var(--th-primary-light);
        &::after {
          transform: translateX(5px);
        }
      }
    }
  }
}
.login-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../#{$login-bg}') no-repeat bottom center / cover;
}


@media (min-width: 3500px) {
  .login-body-wrapper {
    .main-brand {
      width: 350px;
      height: 106px;
      margin-top: 140px;
    }
    .title {
      min-height: calc(100vh - 462px);
      .main-title {
        padding-right: 50px;
        font-size: 70px;
      }
    }
    .google-login {
      padding-left: 50px;
      &__button {
        padding: 24px 34px 24px 124px;
        background-size: 66px;
        font-size: 40px;
        background-position-x: 30px;
        &::after {
          width: 70px;
          height: 30px;
          margin-left: 20px;
        }
      }
    }
  }
}
