// sass-lint:disable no-transition-all
.ar-common-toggle {
  display: flex;
  align-items: center;
  padding-left: 0;
  &__label-text {
    margin-left: 10px;
    color: $th-primary-color-alt;
  }
  &__swich {
    display: inline-block;
    position: relative;
    width: 55px;
    height: 30px;
    margin-bottom: 0;
  }
  &__field {
    width: 0;
    height: 0;
    opacity: 0;
    &:checked + .slider {
      background-color: color('primary');
    }
    &:focus + .slider {
      box-shadow: $box-shadow-1;
    }
    &:checked + .slider::before {
      transform: translateX(26px);
    }
  }
  &__slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.3s linear;
    border-radius: 34px;
    background-color: color('secondary');
    cursor: pointer;
    &::before {
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: 22px;
      height: 22px;
      transition: all 0.3s linear;
      border-radius: 50%;
      background-color: color('pure-white');
      content: '';
    }
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-toggle {
    &__swich {
      width: 75px;
      height: 38px;
    }
    &__slider {
      &::before {
        bottom: 2px;
        left: 3px;
        width: 34px;
        height: 34px;
      }
    }
    &__field {
      &:checked + .slider::before {
        transform: translateX(36px);
      }
    }
  }
}
