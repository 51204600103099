// sass-lint:disable no-url-domains, no-url-protocols

$font-path: '../../../fonts/';

@font-face {
  font-family: 'bentonsans_regularregular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}bentonsans_regular-webfont.woff2') format('woff2'),
  url('#{$font-path}bentonsans_regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'bentonsans_mediumregular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}bentonsans_medium-webfont.woff2') format('woff2'),
  url('#{$font-path}bentonsans_medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'bentonsans_boldregular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}bentonsans_bold-webfont.woff2') format('woff2'),
  url('#{$font-path}bentonsans_bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'open_sans_regular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}OpenSans-Regular.woff2') format('woff2'),
  url('#{$font-path}OpenSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'open_sans_medium';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}OpenSans-Medium.woff2') format('woff2'),
  url('#{$font-path}OpenSans-Medium.woff') format('woff');
}
@font-face {
  font-family: 'open_sans_bold';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}OpenSans-SemiBold.woff2') format('woff2'),
  url('#{$font-path}OpenSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'lota-grotesque-bold';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}Lota_Grotesque_Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'lota-grotesque-regular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}Lota_Grotesque_Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'lota-grotesque-light';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}Lota_Grotesque_Light.woff2') format('woff2');
}
