// sass-lint:disable no-misspelled-properties
.ar-common-posts {
  &__list {
    display: grid;
    margin: 0 0 50px;
    gap: 30px 0;
  }
  &--component {
    .ar-common-post {
      &__container {
        padding: 9px 100px 12px 17px;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .ar-common-posts {
    &__list {
      display: grid;
      margin: 0 0 53px;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ar-common-posts {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-posts {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }
}

@media (min-width: 1600px) {
  .ar-common-posts {
    &__list {
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-posts {
    &__list {
      grid-template-columns: repeat(7, 1fr);
      gap: 30px;
    }
  }
}
