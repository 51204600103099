// sass-lint:disable no-invalid-hex
$colors: (
  'pure-white': #fff,
  'pure-black': #000,
  'primary': #6b00df,
  'primary-light': #b580ef,
  'primary-dark': #250241,
  'secondary': #979797,
  'platinum': #e7e7e7,
  'kiwi': #6bdb48,
  'dark-jungle-blue': #141b2b,
  'eerie-black': #0f1624,
  'charcoal': #313a4d,
  'picton-blue': #2fb7e6,
  'light-blue': #309dd3,
);

// colours
$black-4: rgba(0, 0, 0, 0.5);
$gray-background: #e5e5e5;
