// sass-lint:disable no-important
.swal2 {
  &-cancel {
    background-color: color('secondary') !important;
  }
  &-confirm {
    background-color: color('primary') !important;
  }
  &-styled {
    &.swal2-confirm,
    &.swal2-deny,
    &.swal2-cancel {
      border-radius: 50px;
    }
    &:focus,
    &:active {
      box-shadow: 0 0 0 0.2rem rgba(color('primary'), 0.3) !important;
    }
  }
}

.swal2-question {
  border-color: color('primary') !important;
  color: color('primary') !important;
}
