.ar-common-component {
  &__button {
    position: absolute;
    right: 40px;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 2;
    .ar-btn--primary {
      padding: 10px 10px 10px 25px;
      font-size: 15px;
      &.nav-link {
        display: block;
        min-width: 200px;
        border-radius: 30px;
        &::before {
          @include plus-icon;
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    max-width: 1275px;
  }
}

@media (min-width: 1141px) {
  .ar-common-component {
    &__wrapper {
      width: calc(100% - 228px);
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(lg) {
  .ar-common-component {
    &__button {
      right: 59px;
      bottom: 63px;
    }
    &__wrapper {
      width: 100%;
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-component__button {
    .ar-btn--primary {
      padding: 10px 24px 10px 48px;
      font-size: 18px;
      &.nav-link {
        &::before {
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
