.ar-common-breadcrumb {
  width: 100%;
  margin: 0 0 13px;
  padding: 24px 0 24px 30px;
  background: $th-page-bg;
  color: $breadcrumb-color;
  font-size: $h3-font-size;
  letter-spacing: normal;
  line-height: normal;
  @media (min-width: 3500px) {
    font-size: 20px;
  }
  &__link {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $breadcrumb-color;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    &.ar-common-breadcrumb__link {
      &[type='button'] {
        margin: 0;
      }
    }
  }
  &__page {
    color: $th-breadcrumb-color;
  }
  .svg-inline--fa {
    margin: 0 6px;
  }
}
