// sass-lint:disable no-important, class-name-format
body {
  background: $th-page-bg;
  scroll-behavior: smooth;
  .ReactModal__Overlay--after-open {
    z-index: 1200 !important;
  }
}

a {
  &[href] {
    &:hover {
      color: color('pure-white');
    }
  }
}

.App {
  min-height: 100vh;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: $select-box-shadow;
}

.modal-backdrop {
  &.show {
    opacity: 0.85;
  }
}

@media (min-width: 3500px) {
  body {
    font-size: 18px;
  }
  .form-control {
    font-size: 24px;
  }
  label {
    font-size: 24px;
  }
}

.bg-primary {
  background-color: var(--th-primary-color) !important;
}
