// sass-lint:disable force-attribute-nesting
// sass-lint:disable no-misspelled-properties
.ar-common-form {
  @include dropdown-box-shadow;
  $form: '.ar-common-form';
  $input: '.rctslct';
  position: relative;
  margin: 20px 30px;
  border-radius: 25px;
  background-color: $th-secondary-color;
  &__input {
    @include body-normal;
  }
  &__group {
    margin: 0 0 27px;
    label:not(.ar-common-toggle__swich) {
      margin: 0;
      color: $th-primary-color-alt;
      font-size: $h3-font-size;
      letter-spacing: normal;
      line-height: normal;
    }
    h3,
    h4,
    h5 {
      color: $th-primary-color-alt;
    }
    .form-control {
      border-radius: 30px;
    }
    .rdp-input-group {
      .rdp-calendar {
        .bg-primary {
          border-radius: 0.3rem !important; // sass-lint:disable-line no-important
          background: color(
            'primary'
          ) !important; // sass-lint:disable-line no-important
          color: color('pure-white');
        }
        .text-primary {
          color: color(
            'primary'
          ) !important; // sass-lint:disable-line no-important
        }
        tbody {
          tr {
            td {
              &:hover {
                border-radius: 0.3rem !important; // sass-lint:disable-line no-important
                background: $drop-down-hover;
                color: color('pure-white');
              }
            }
          }
        }
      }
    }
  }
  &__datepicker {
    .form-control {
      &:not([placeholder='select...']) {
        pointer-events: none;
      }
    }
    .rdp-overlay {
      z-index: 1050;
    }
  }
  &__control {
    border-radius: 5px;
    border-color: transparent;
  }
  &__select {
    width: 100%;
  }
  &__checkbox {
    margin: 0 0 0 20px;

    input {
      border-color: $th-primary-color-alt;
      background-color: transparent;
    }
  }
  #{$input}__control {
    border-radius: 25px;
    border-color: transparent;
    background-color: $th-element-bg;
  }
  #{$input}__value-container {
    padding-left: 16px;
  }
  #{$input}__single-value {
    @include body-normal;
    color: $breadcrumb-color;
  }
  #{$input}__multi-value {
    @include body-normal;
    border-radius: 20px;
    background-color: var(--th-text-white);
    overflow: hidden;
    &__remove {
      color: var(--th-primary-dark);
    }
  }

  #{$input}__indicator:not(#{$input}__clear-indicator):not(#{$input}__loading-indicator) {
    @include dropdown-icon;
    margin: 0 16px;
    padding: 0;
    svg {
      display: none;
    }
  }
  #{$input}__indicator-separator {
    display: none;
  }
  #{$input}__menu {
    width: 100%;
    color: color('pure-black');
  }
  #{$input} {
    &__multi-value__label {
      @include body-normal;
      padding: 3px 6px 1px;
    }
  }

  input[type='url'],
  input[type='text'],
  input[type='number'],
  textarea {
    border-color: transparent;
    background-color: $th-element-bg;
    color: $th-primary-color-alt;
  }
  input[name='datePicker'] {
    color: transparent;
  }
  .form-group__hint {
    @include body-normal;
    color: $th-primary-color-alt;
  }
  .css-1pahdxg-control,
  .form-control:focus {
    box-shadow: $select-box-shadow;
  }

  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: transparent;
  }
  &--setting {
    .form-control {
      border-radius: 15px 0 0 15px;
    }
    .btn-danger {
      border-radius: 0 15px 15px 0;
    }
  }
}

.form-check-input {
  accent-color: #6b00df;
}

.btn-close {
  &:focus {
    box-shadow: $select-box-shadow;
  }
}
.ar-common-form--fixed-max-width {
  max-width: 950px;
  .rctslct__single-value {
    @include body-normal;
    color: var(--th-primary-color-alt);
  }
}
@include media-breakpoint-up(lg) {
  .ar-common-form {
    padding: 35px 58px 50px;
    box-shadow: $th-form-box-shadow;
  }
}

@media (min-width: 2000px) {
  .ar-common-form {
    &--setting {
      .form-control {
        max-width: 1500px;
      }
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-form {
    .rctslct__multi-value__label {
      padding: 10px;
    }
  }
}
