.rctslct__input {
  input {
    // overrides inline element style
    color: var(
      --th-primary-color-alt
    ) !important; // sass-lint:disable-line no-important
    line-height: normal;
  }
}

.ar-edit-page__moodboard-popup {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  transform: translateX(-100%);
  animation: slide-in 0.5s backwards;
  .modal-content {
    height: 100%;
    background-color: $th-primary-color;

    .modal-header {
      display: block;
      border-bottom: 1px solid $th-border-dark;
      color: $th-primary-color-alt;
    }
    .modal-body {
      height: 100vh;
      overflow: auto;
      .card {
        border: 1px solid $th-border-dark;
        color: $th-primary-color-alt;
        &-body {
          background-color: $th-primary-color;
        }
      }
    }
    .modal-footer {
      border-top: 1px solid $th-border-dark;
    }
  }
  .rctslct {
    &__control {
      border: 0;
      border-radius: 22px;
      background-color: var(--th-element-bg-light);
      * {
        @include body-normal;
        line-height: normal;
      }
      .rctslct__value-container {
        min-height: 36px;
        padding: 5px 18px 4px;
      }
      .rctslct__placeholder {
        @include body-normal;
      }
      .rctslct__indicator {
        color: $breadcrumb-color;
        &-separator {
          opacity: 0.2;
        }
      }
    }
    &__menu {
      z-index: 2;
      &-list {
        padding-top: 0;
      }
      * {
        @include body-normal;
      }
    }
    &__single-value {
      color: $th-secondary-color-alt;
    }
  }
}

.ar-edit-page__moodboard-gallery-selections {
  .seclected {
    pointer-events: none;
    .reference-added {
      display: none;
    }
    &::after {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: $th-ref-overlay;
      color: $th-text-white;
      content: 'Selected';
    }
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (min-width: 1441px) {
  .ar-edit-page__moodboard-popup {
    .rctslct__control {
      .rctslct__placeholder {
        @include body-normal;
      }
      .rctslct__value-container {
        min-height: 42px;
      }
    }
  }
}

@media (min-width: 3500px) {
  .ar-edit-page {
    &__moodboard-popup {
      width: 53%;
      .rctslct {
        &__control {
          border-radius: 80px;
          * {
            @include body-normal;
          }
          .rctslct__value-container {
            min-height: 74px;
            padding-left: 30px;
          }
        }
        &__menu {
          * {
            @include body-normal;
          }
        }
      }
    }
  }
}
