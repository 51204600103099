//font families
$bentonsans-regular: 'bentonsans_regularregular', sans-serif;
$bentonsans-medium: 'bentonsans_mediumregular', sans-serif;
$bentonsans-bold: 'bentonsans_boldregular', sans-serif;
$opensans-regular: 'opensans-regular', sans-serif;
$opensans-medium: 'open_sans_medium', sans-serif;
$opensans-bold: 'open_sans_bold', sans-serif;

$lota-grotesque-bold: 'lota-grotesque-bold', sans-serif;
$lota-grotesque-regular: 'lota-grotesque-regular', sans-serif;
$lota-grotesque-light: 'lota-grotesque-light', sans-serif;

// Image Path
$image-base-path: '../../images/';
$external-link: '#{$image-base-path}external-link.svg';
$tick-icon: '#{$image-base-path}tick-icon.png';
$save-image: '#{$image-base-path}save.svg';
$edit-image: '#{$image-base-path}edit.svg';
$update-image: '#{$image-base-path}update.svg';
$cancel-image: '#{$image-base-path}cancel.svg';
$close-btn: '#{$image-base-path}close-btn.png';
$loading-icon: '#{$image-base-path}loading_icon.gif';
$triangle: '#{$image-base-path}triangle.png';
$play-button: '#{$image-base-path}play-button.svg';
$icon-sprites: '#{$image-base-path}icon-sprite-v1.svg';
$down-icon: '#{$image-base-path}down.png';
$left-arrow-icon: '#{$image-base-path}left-arrow-icon.png';
$right-arrow-icon: '#{$image-base-path}right-arrow-icon.png';
$pagination-dropdown: '#{$image-base-path}pagination-dropdown.png';
$icon-trash: '#{$image-base-path}icon-trash.svg';
$delete-icon: '#{$image-base-path}icons/delete-icon.svg';
$edit-icon: '#{$image-base-path}icons/edit-icon.svg';
$edit-page-icon: '#{$image-base-path}icons/edit-page-icon.svg';
$delete-hover-icon: '#{$image-base-path}icons/delete-hover-icon.svg';
$edit-hover-icon: '#{$image-base-path}icons/edit-hover-icon.svg';
$edit-page-hover-icon: '#{$image-base-path}icons/edit-page-hover-icon.svg';
$download-drop: '#{$image-base-path}icons/download-drop.svg';
$download-hover-drop: '#{$image-base-path}icons/download-hover-drop.svg';
$login-bg: '#{$image-base-path}argo_login_bg.svg';
$e25-logo-white: '#{$image-base-path}eight25_logo_white.svg';
$icon-components: '#{$image-base-path}icons/component.svg';
$icon-logout: '#{$image-base-path}icons/logout.svg';
$icon-moodgen-ref: '#{$image-base-path}icons/moodgen-ref.svg';
$icon-moodgen: '#{$image-base-path}icons/moodgen.svg';
$icon-prototype: '#{$image-base-path}icons/prototype.svg';
$icon-ref-screens: '#{$image-base-path}icons/reference-screen.svg';
$icon-settings: '#{$image-base-path}icons/settings.svg';
$icon-gogle: '#{$image-base-path}icons/google_icon.svg';
$icon-long-arrow-right: '#{$image-base-path}icons/long-arrow-right.svg';
$icon-duplicate-white: '#{$image-base-path}icons/icon-duplicate.svg';
$drag-icon: '#{$image-base-path}icons/drag-icon.svg';
$gradient-stripe: '#{$image-base-path}gradient-stripe.svg';
$delete-icon-set: '#{$image-base-path}icons/delete-icon-set.svg';

//colors
$breadcrumb-color: #89949f;
$post-bg-color: #f4f4f4;
$download-color: #00c856;
$component-text-color: #9ea2a4;
$drop-down-hover: rgba(color('primary'), 0.4);
$select-box-shadow: 0 0 0 0.2rem rgba(color('primary'), 0.3);

//opacity
$default-opcity: 0.65;
$hover-opacity: 1;

//Box-shdows
$search-panel-bg-shadow: 0 8px 24px rgba(69, 69, 80, 0.1);
$pagination-shadow: drop-shadow(0 0 8px rgba(0, 0, 0, 0.05));

// Theme variables
$th-primary-color: var(--th-primary-color);
$th-secondary-color: var(--th-secondary-color);
$th-tertiary-color: var(--th-tertiary-color);
$th-primary-color-alt: var(--th-primary-color-alt);
$th-secondary-color-alt: var(--th-secondary-color-alt);
$th-tertiary-color-alt: var(--th-tertiary-color-alt);
$th-shadow: var(--th-shadow);
$th-sidebar-background-color: var(--th-sidebar-background-color);
$th-invert-color: var(--th-invert-color);
$th-light-blue: var(--th-light-blue);
$th-icon-edit: var(--th-icon-edit);
$th-icon-delete: var(--th-icon-delete);
$th-form-box-shadow: var(--th-form-box-shadow);
$th-select-box-shadow: var(--select-box-shadow);
$th-shadow-hover: var(--th-shadow-hover);
$th-brightness: var(--th-brightness);
$th-primary-dark: var(--th-primary-dark);
$th-light-grey: var(--th-light-grey);
$th-light-border: var(--th-light-border);
$th-page-overlay: var(--th-page-overlay);
$th-border-dark: var(--th-border-dark);
$th-element-bg: var(--th-element-bg);
$th-bg-color: var(--th-bg-color);
$th-breadcrumb-color: var(--th-breadcrumb-color);
$th-ref-overlay: var(--th-ref-overlay);
$th-text-white: var(--th-text-white);
$th-bg-grayed: var(--th-bg-grayed);
$th-primary-hover: var(--th-primary-hover);
$th-tag-text: var(--th-tag-text);
$th-invert-icon: var(--th-invert-icon);
$th-page-bg: var(--th-page-bg);
$th-post-border: var(--th-post-border);
$th-white-invert: var(--th-white-invet);
$th-element-bg-light: var(--th-element-bg-light);
$th-primary-light: var(--th-primary-light);
$th-white-gray: var(--th-white-gray);
$th-primary-dark-alt: var(--th-primary-dark-alt);
$th-dark-grey: var(--th-dark-grey);

//borders
$border-1: 2px solid color('pure-white');
$border-2: 2px solid transparent;
$border-3: 1px solid color('primary');
$border-4: 2px solid color('primary');
$border-5: solid 1px $th-secondary-color-alt;

//box-shadow
$box-shadow-1: 0 0 1px color('pure-white');
