// sass-lint:disable indentation
@mixin icon-sprite {
  background-image: url('../#{$icon-sprites}');
  background-repeat: no-repeat;
  background-size: 200px 250px;
}

@mixin delete-icon-dark {
  @include icon-sprite;
  width: 19px;
  height: 24px;
  background-position: -3px -3px;
}

@mixin delete-icon-round {
  @include icon-sprite;
  width: 30px;
  height: 30px;
  background-position: -48px -68px;
}

@mixin delete-icon-light {
  @include icon-sprite;
  width: 19px;
  height: 24px;
  background-position: -45px -3px;
}

@mixin edit-icon-dark {
  @include icon-sprite;
  width: 19px;
  height: 24px;
  background-position: -24px -3px;
}

@mixin edit-icon-round {
  @include icon-sprite;
  width: 30px;
  height: 30px;
  background-position: -82px -68px;
}

@mixin page-edit-icon-round {
  @include icon-sprite;
  width: 30px;
  height: 30px;
  background-position: -150px -70px;
}

@mixin edit-icon-light {
  @include icon-sprite;
  width: 19px;
  height: 24px;
  background-position: -67px -3px;
}

@mixin timer-icon {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -87px -3px;
}

@mixin external-link-icon {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -130px -3px;
}

@mixin components-icon {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -89px -23px;
}

@mixin prototype-icon {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -91px -45px;
}

@mixin moodboard-config-icon {
  @include icon-sprite;
  width: 22px;
  height: 20px;
  background-position: -154px -24px;
}

@mixin moodgen-reference-icon {
  @include icon-sprite;
  width: 22px;
  height: 20px;
  background-position: -157px -45px;
}

@mixin reference-screens-icon {
  @include icon-sprite;
  width: 22px;
  height: 20px;
  background-position: -132px -24px;
}
@mixin moodboard-references-icon {
  @include icon-sprite;
  width: 22px;
  height: 20px;
  background-position: -88px -24px;
}

@mixin settings-icon {
  @include icon-sprite;
  width: 22px;
  height: 20px;
  background-position: -107px -3px;
}

@mixin drop-arrow-icon-dark {
  @include icon-sprite;
  width: 8px;
  height: 4px;
  background-position: -173px -5px;
}

@mixin drop-arrow-icon-light {
  @include icon-sprite;
  width: 10px;
  height: 4px;
  background-position: -183px -5px;
}

@mixin search-icon-dark {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -3px -28px;
}

@mixin search-icon-light {
  @include icon-sprite;
  width: 19px;
  height: 20px;
  background-position: -24px -28px;
}

@mixin left-arrow-white {
  @include icon-sprite;
  width: 11px;
  height: 18px;
  background-position: -44px -27px;
}

@mixin right-arrow-white {
  @include icon-sprite;
  width: 11px;
  height: 18px;
  background-position: -55px -27px;
}

@mixin left-arrow-dark {
  @include icon-sprite;
  width: 11px;
  height: 18px;
  background-position: -66px -27px;
}

@mixin right-arrow-dark {
  @include icon-sprite;
  width: 11px;
  height: 18px;
  background-position: -77px -27px;
}

@mixin side-menu-toggle {
  @include icon-sprite;
  width: 11px;
  height: 20px;
  background-position: -1px -90px;
}

@mixin download-icon-round {
  @include icon-sprite;
  width: 30px;
  height: 30px;
  background-position: -116px -71px;
}

@mixin toggle-down-icon {
  @include icon-sprite;
  width: 10px;
  height: 6px;
  background-position: -14px -94px;
}

@mixin pagination-left-arrow {
  @include icon-sprite;
  width: 14px;
  height: 9px;
  background-position: -27px -94px;
}

@mixin pagination-right-arrow {
  @include icon-sprite;
  width: 14px;
  height: 9px;
  background-position: -45px -102px;
}

@mixin pagination-white-left-arrow {
  @include icon-sprite;
  width: 14px;
  height: 9px;
  background-position: -63px -102px;
}

@mixin pagination-white-right-arrow {
  @include icon-sprite;
  width: 14px;
  height: 9px;
  background-position: -81px -102px;
}

@mixin dropdown-icon {
  @include icon-sprite;
  width: 14px;
  height: 8px;
  background-position: -98px -102px;
}

@mixin plus-icon {
  @include icon-sprite;
  display: block;
  position: absolute;
  top: 14px;
  left: 16px;
  width: 14px;
  height: 14px;
  background-position: -119px -108px;
  content: '';
}
