.ar-common-tab {
  $common-tab: '.ar-common-tab';
  width: fit-content;
  margin: 0 0 12px;
  border: 0;
  border-radius: 100px;
  background-color: $th-primary-dark-alt;
  &.nav {
    #{$common-tab} {
      &__item {
        .nav-link,
        .nav-link:hover {
          color: color('pure-white');
        }
        .nav-link {
          @include body-normal;
          padding: 7px 34px;
          border-width: 0;
          border-radius: 100px;
          font-family: $opensans-bold;
          line-height: 1.5;
          &:hover {
            border-width: 0;
          }
          &.active {
            margin-bottom: 0;
            background-color: color('primary');
          }
        }
      }
    }
  }
  &__titles {
    @include body-normal;
    color: $th-breadcrumb-color;
    font-family: $lota-grotesque-light;
    span {
      display: inline-block;
      margin: 0 20px 20px 0;
      strong {
        font-family: $lota-grotesque-regular;
        font-weight: $font-weight-light;
      }
    }
  }
  &__content {
    padding-top: 10px;
  }
}

@media (min-width: 1920px) {
  .ar-common-tab {
    $common-tab: '.ar-common-tab';
    margin-bottom: 30px;
    &.nav {
      #{$common-tab} {
        &__item {
          .nav-link {
            padding: 8px 40px;
          }
        }
      }
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-tab {
    $common-tab: '.ar-common-tab';
    &.nav {
      #{$common-tab} {
        &__item {
          .nav-link {
            padding: 20px 58px;
          }
        }
      }
    }
  }
}
