.ar-common-title {
  @include title-one;
  margin: 0 0 30px;
  padding: 0 0 0 30px;
  border: 0;
  background-color: transparent;
  font-size: 28px;
  font-weight: $font-weight-medium;
  @media (min-width: 3500px) {
    font-size: 32px;
  }
}
