// sass-lint:disable no-important
.ar-common-sidebar {
  margin: 0 0 30px;
  padding: 40px 0 0;
  &__profile {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
  }
  &__image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
  }
  &__name {
    display: none;
    margin-left: 20px;
    color: color('pure-white');
    font-family: $lota-grotesque-regular;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 18px;
    white-space: nowrap;
  }
  &__toggle {
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    transform: translateX(50%) rotate(0deg);
    transition: opacity 0.3s ease;
    border: 1px solid color('primary');
    border-radius: 50%;
    background-color: color('primary-dark');
    opacity: 0;
    z-index: 1;
    &::before {
      @include side-menu-toggle;
      position: absolute;
      top: 6px;
      left: 10px;
      content: '';
    }
    &:hover {
      opacity: 1;
    }
  }
  &__inner {
    padding: 0;
  }
  &__nav-link,
  .nav-link {
    display: flex;
    position: relative;
    align-items: center;
    height: 56px;
    margin: 0 0 5px;
    padding-left: 17px;
    transition: margin 0.3s ease-in-out;
    border-radius: 28px;
    color: color('pure-white');
    font-family: $lota-grotesque-regular;
    font-size: $h3-font-size;
    letter-spacing: 0.5px;
    line-height: 22px;
    text-decoration: none;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
    // &::before {
    //   position: absolute;
    //   top: 50%;
    //   left: 17px;
    //   width: 18px;
    //   height: 18px;
    //   transform: translateY(-50%);
    //   content: '';
    // }
    &:hover {
      background-color: $th-primary-hover;
      text-decoration: none;
    }
    &[href='/components'] {
      &::before {
        background: url('../#{$icon-components}') no-repeat center center / contain;
      }
    }
    &[href='/prototypes'] {
      &::before {
        background: url('../#{$icon-prototype}') no-repeat center center / contain;
      }
    }
    &[href='/moodgen'] {
      &::before {
        background: url('../#{$icon-moodgen}') no-repeat center center / contain;
      }
    }
    &[href='/reference-screens'] {
      &::before {
        background: url('../#{$icon-ref-screens}') no-repeat center center / contain;
      }
    }
    &[href='/moodgen-referencess'] {
      &::before {
        background: url('../#{$icon-moodgen-ref}') no-repeat center center / contain;
      }
    }
    &[href='/settings'] {
      &::before {
        background: url('../#{$icon-settings}') no-repeat center center / contain;
      }
    }

    &[href='/'] {
      &::before {
        background: url('../#{$icon-logout}') no-repeat center center / contain;
      }
    }
  }
  &__link-active {
    background-color: rgba(color('primary'), 1) !important;
    color: color('pure-white');
  }
  .navbar {
    height: 100%;
    padding: 0;
    &-toggler {
      display: none;
    }
    &-collapse {
      display: block !important; // Overwrite bootstrap plugin
      padding: 20px 15px 50px;
      transform: translateX(0);
      // border-radius: 15px;
      background-color: $th-sidebar-background-color;
    }
  }
  .navbar-nav {
    height: 100%;
    .nav-item {
      &:last-child {
        margin-top: auto;
      }
    }
  }
}
.col-sidebar {
  width: 0;
  transition: width 0.3s ease;
  z-index: 1022;
}

@include media-breakpoint-up(md) {
  .ar-common-sidebar {
    .navbar-collapse {
      width: 90px;
      height: 100%;
      transition: 0.3s ease;
      .nav-item {
        span {
          display: none;
          position: relative;
          left: 18px;
          white-space: nowrap;
        }
      }
    }
  }
  .navbar-wide {
    .navbar-collapse {
      width: 300px;
      overflow-x: hidden;
      .nav-item {
        span {
          display: block;
        }
      }
    }
    .ar-common-sidebar__toggle {
      opacity: 1;
    }
    .ar-common-sidebar__name {
      display: block;
    }
  }
  .navbar-wide-sticky {
    width: 210px;
    .navbar-collapse {
      width: 300px;
      .nav-item {
        span {
          display: block;
        }
      }
    }
    .ar-common-sidebar__toggle {
      transform: translateX(50%) rotate(180deg);
    }
    .ar-common-sidebar__name {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0;
    z-index: 6;
  }
}
// Custom media query - Get sidebar outside of the grid
@media (min-width: 1400px) {
  .ar-common-sidebar {
    &__profile {
      margin: 0 0 50px;
      text-align: center;
    }
    .navbar-collapse {
      height: 100%;
      padding: 60px 18px 18px;
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-sidebar {
    &__image {
      width: 104px;
      height: 104px;
    }
    &__name {
      font-size: 26px;
    }
    .navbar-collapse {
      width: 150px;
      .nav-item {
        span {
          left: 48px;
        }
      }
    }
    &__nav-link,
    .nav-link {
      display: flex;
      align-items: center;
      width: 104px;
      height: 104px;
      padding-left: 33px;
      border-radius: 1000px;
      font-size: 26px;
      img {
        width: 36px;
        height: 36px;
        object-fit: contain;
      }
    }
  }
  .navbar-wide  {
    .navbar-collapse {
      width: 520px;
    }
    .ar-common-sidebar__nav-link,
    .nav-link {
      width: 100%;
    }
  }

  .navbar-wide-sticky {
    width: 360px;
    .navbar-collapse {
      width: 520px;
      .ar-common-sidebar__nav-link,
      .nav-link {
        width: 100%;
      }
    }
  }
}
