.ar-page-load {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $th-page-overlay;
  z-index: 1;
  &__text {
    padding: 10px;
    transform: translate(0, -100%);
    color: var(--th-text-white);
    font-size: large;
  }
}
