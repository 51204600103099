// sass-lint:disable no-misspelled-properties no-vendor-prefixes
html {
  background-color: $th-primary-color;
}

* {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 8px var(--th-element-bg);
  }
  &::-webkit-scrollbar-thumb {
    outline: 1px solid var(--th-element-bg);
    background-color: var(--th-element-bg);
  }
}

.ar-common-page {
  padding: 50px 0;
  background-color: $th-page-bg;
  color: $th-primary-color-alt;
  &__inner {
    display: block;
  }
  &__button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 15px;
  }
  .popover-header {
    color: $th-primary-dark;
  }
  &__container {
    display: flex;
  }
  &__empty {
    margin-top: 10%;
    h2 {
      font-family: $lota-grotesque-light;
      font-size: 24px;
    }
    h3 {
      font-family: $lota-grotesque-light;
      font-size: 48px;
    }
    p {
      max-width: 457px;
      margin: 0 auto;
      font-family: $lota-grotesque-light;
      font-size: large;
      line-height: 1.4;
    }
    h3,
    p,
    button {
      margin-top: 28px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ar-common-page {
    padding: 50px 0 0;
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-page {
    padding: 0 0 40px 90px;
    overflow-x: clip;
  }
}

@media (min-width: 3500px) {
  .ar-common-page {
    padding-left: 160px;
  }
}
