.ar-common-checkbox {
  position: relative;
  cursor: pointer;
  span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 5px 15px -3px 0;
    border: $border-5;
    border-radius: 50px;
    pointer-events: none;
  }
  input {
    &[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 20px;
      width: 25px;
      height: 25px;
      opacity: 0;
      &:checked + span {
        border-color: $th-secondary-color-alt;
        &::after {
          @include before-after;
          top: 10px;
          left: 9px;
          width: 6px;
          height: 11px;
          background: url('../../../images/tick-icon.png') 100% 100% / contain no-repeat;
        }
      }
    }
  }
}
