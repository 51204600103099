// sass-lint:disable no-misspelled-properties
.ar-common-search-panel {
  position: relative;
  $form: '.ar-common-form';
  justify-content: space-between;
  margin: 50px 0;
  padding: 26px 30px 30px;
  border-radius: 10px;
  background-color: transparent;
  box-shadow: none;
  z-index: 2;
  #{$form}__group {
    margin: 0 0 15px;
  }
  .form-group {
    display: flex;
    align-items: center;
    &.ar-common-form {
      &-search {
        position: relative;
        &::before {
          @include before-after;
          @include search-icon-dark;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }
      }
    }
    label {
      margin: 0 8px 0 0;
      font-family: $lota-grotesque-bold;
    }
    .form-control {
      border-radius: 25px;
      &::placeholder {
        color: $breadcrumb-color;
      }
    }
  }
  .search {
    padding-left: 50px;
  }
  #{$form}__checkbox {
    margin-left: 35px;
    white-space: nowrap;
    label {
      margin: 0;
    }
  }
  &--no-label,
  &--with-label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }
  &-wrapper {
    position: sticky;
    top: 0;
    margin: 0 0 10px;
    background: $th-page-bg;
    z-index: 4;
    .ar-common-tab {
      &.nav {
        &.nav-tabs {
          margin: 12px 0 20px 40px;
        }
      }
    }
  }
  .custom-switch {
    height: 40px;
    .custom-control-label {
      &::before {
        width: 75px;
        height: 38px;
        border-radius: 19px;
        border-color: var(--th-element-bg);
        background: var(--th-element-bg);
      }
      &::after {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: color('pure-white');
      }
    }
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX(36px);
      }
      &::before {
        background: color('primary');
      }
    }
    &.custom-control {
      position: relative;
      bottom: 2px;
      &-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: color('primary');
      }
    }
    .custom-control {
      &-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: transparent;
      }
      &-input:focus ~ .custom-control-label::before {
        box-shadow: $select-box-shadow;
      }
      &-input:not(:disabled):active ~ .custom-control-label::before {
        border-color: transparent;
        background: color('primary');
      }
    }
  }
  &--reference {
    .ar-common-search-panel {
      &__group {
        width: 100%;
      }
    }
  }
  &-wrapper-component {
    .ar-common-form__group {
      &-choices {
        .ar-common-form__select {
          .rctslct__value-container {
            max-width: 150px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-search-panel {
    $search: '.ar-common-search-panel';
    $form: '.ar-common-form';
    display: flex;
    align-items: flex-end;
    justify-content: normal;
    margin: 0;
    padding: 20px 8px 5px 30px;
    gap: 0 15px;
    #{$form}__group-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      // margin: 35px 0 0;
      gap: 0 8px;
      #{$form}__group {
        &::after {
          display: none;
        }
      }
    }
    #{$form}__group {
      position: relative;
      width: 100%;
      max-width: 100%;
      margin: 0;
      &.ar-common-form-custom-switch {
        max-width: 170px;
        z-index: 0;
      }
    }
    .form-group {
      display: flex;
      align-items: center;
      label {
        @include body-normal;
        font-family: $lota-grotesque-light;
        letter-spacing: 0.5px;
      }
      .form-control {
        height: 36px;
        text-overflow: ellipsis;
      }
    }
    &--no-label,
    &--with-label {
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 30px 30px 25px;
    }
    &--with-label {
      align-items: flex-end;
      #{$form}__group {
        &::after {
          display: none;
        }
      }
    }
    &--two-filter {
      padding: 30px 30px 25px;
      #{$search}__group {
        width: 50%;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .ar-common-search-panel {
    .form-group {
      min-width: 160px;
    }
  }
}

@media (min-width: 1441px) {
  .ar-common-search-panel {
    justify-content: normal;
    .ar-common-form__group {
      max-width: 278px;
    }
    .form-group {
      .form-control {
        height: 42px;
      }
    }
  }
}

@media (min-width: 2000px) {
  .ar-common-search-panel {
    justify-content: normal;
    .ar-common-form__group {
      max-width: 492px;
    }
    &-wrapper-component {
      .ar-common-form__group {
        &-choices {
          .ar-common-form__select {
            .rctslct__value-container {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-search-panel {
    align-items: center;
    .ar-common-form {
      &__group {
        max-width: 650px;
      }
    }
    .form-group {
      &.ar-common-form-search {
        &::before {
          left: 30px;
          transform: translateY(-50%) scale(1.5);
        }
      }
      .form-control {
        height: 74px;
        padding-left: 70px;
        border-radius: 80px;
      }
    }
  }
}
