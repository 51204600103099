.ar-common-popup {
  &.modal-dialog {
    width: 100%;
    .modal-content {
      background-color: transparent;
      .modal-body {
        padding: 0;
        overflow: hidden;
        &.ar-common-popup__body {
          display: grid;
          grid-template-areas: 'previewArea contentArea' 'previewArea footerArea';
          grid-template-columns: 44% 56%;
          grid-template-rows: 1fr minmax(64px, 250px);
        }
      }
    }
  }
  .ar-common-search-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ar-common-form__group {
      max-width: 100%;
    }
  }
  .form-control {
    height: calc(0.75rem + 0.75rem + 8px);
  }
  &__prv,
  &__content {
    padding: 0 30px 30px;
    overflow-y: auto;
  }
  &__content,
  &__footer {
    background-color: var(--th-primary-color);
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  &__prv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    z-index: 1;
    grid-area: previewArea;
    overflow-y: auto;
    img {
      width: 100%;
    }
  }
  &__content {
    height: calc(100vh - 70px);
    grid-area: contentArea;
  }
  &__footer {
    padding: 15px 30px;
    grid-area: footerArea;
  }
  &__selected {
    @include body-normal;
    color: var(--th-white-invet);
  }
}

@media (min-width: 1920px) {
  .ar-common-popup {
    &__grid {
      grid-template-columns: repeat(3, 1fr);
    }
    .ar-common-search-panel {
      display: flex;
      .ar-common-form__group {
        max-width: 100%;
        &.ar-common-form-custom-switch {
          max-width: 180px;
        }
      }
    }
  }
}

@media (min-width: 3500px) {
  .ar-common-popup {
    .form-control {
      height: calc(1.5em + 0.75rem + 8px);
    }
    &__grid {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
