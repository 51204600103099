.ar-common-settings-panel {
  position: fixed;
  top: 50%;
  right: -150px;
  width: 175px;
  padding: 0 0 0 25px;
  transform: translateY(-50%);
  transition: right 0.4s ease-in-out;
  color: color('pure-white');
  font-size: 10px;
  &:hover {
    right: 0;
    transition: right 0.8s ease-in-out;
  }
  &__handle-default {
    position: absolute;
    top: 50%;
    left: -35px;
    width: 100px;
    height: 25px;
    padding-top: 5px;
    transform: translateY(-50%) rotate(-90deg);
    border-radius: 10px 10px 0 0;
    background-color: color('primary');
    text-align: center;
    cursor: pointer;
  }
  &__form-check {
    margin-left: 20px;
    &-label {
      margin: 3px 0 0;
    }
  }
  &__container-default {
    align-items: center;
    min-height: 150px;
    padding: 20px 15px;
    border-radius: 10px 0 0 10px;
    background-color: color('primary');
    .react-switch-bg {
      div {
        display: flex;
        align-items: center;
      }
    }
  }
}
